import * as React from "react";
import styled from "styled-components";
import Layout from "../../components/Layout/index.js";
import { BlogSection } from "../../components/Blog";
import { Seo } from "../../utils/Seo.js";

const BlogPage = () => {
  return (
    <Layout pageClass="blog-index">
      <Seo
        title={`Our Blog | Montebello.digital`}
        desc="Read the latest posts by the team."
      />

      <section>
        <BlogSection />
      </section>
    </Layout>
  );
};

export default BlogPage;
